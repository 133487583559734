import React from "react";
import AppRoutes from "routes";

// mui and provider import
import useTheme from "lib/theme";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

const App: React.FC = () => {
    const theme = useTheme();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppRoutes />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
