import { useState } from "react";
import { motion } from "framer-motion";
import { Menu, X } from "lucide-react";

import Logo from "assets/images/lastbitligi-02.png";


const navItems = [
    {
        name: "Home",
        href: "#hero",
        width: 93,
        position: 10,
    },
    {
        name: "About",
        href: "#about",
        width: 93,
        position: 103,
    },
    {
        name: "Feature",
        href: "#feature",
        width: 104,
        position: 196,
    },
    {
        name: "Pricing",
        href: "#pricing",
        width: 99,
        position: 300,
    },
];

const Header = () => {
    const [activeTab, setActiveTab] = useState(navItems[0].name);
    const [toggleMenu, setToggleMenu] = useState(false);
    return (
        <header className="fixed inset-0 z-50 h-fit will-change-auto">
            <motion.div
                initial={{
                    opacity: 0,
                    y: -60,
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    ease: [0.72, 0.1, 0.4, 1],
                    opacity: { duration: 1.5, delay: 0.6 },
                    duration: 1.5,
                }}
            >
                <div className={`mx-auto grid ${toggleMenu ? "!h-[380px] !overflow-auto" : "!h-fit"} bg-white/5 backdrop-blur-sm transition-all w-full max-w-container grid-cols-2 flex-row flex-nowrap items-start md:items-center justify-between overflow-hidden px-6 py-2 pl-4 md:h-[100px] md:px-[52px] md:py-3 md:pl-8 xl:h-[124px] xl:grid-cols-3 xl:px-[120px] xl:py-4`}>
                    <div className="flex items-center ">
                        <img
                            src={Logo}
                            alt="lastbit"
                            className="aspect-square size-10 md:size-12 lg:size-16"
                        />
                    </div>
                    <div className="relative hidden h-12 flex-row flex-nowrap items-center justify-center xl:flex"></div>
                    {/* <nav className="relative hidden h-12 flex-row flex-nowrap items-center justify-center xl:flex">
                        <div className="relative">
                            <div className="flex items-center backdrop-blur-md rounded-[40px] bg-white/5 px-[10px] py-[7px]">
                                {navItems.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        onClick={() => setActiveTab(item.name)}
                                        className="relative z-10 rounded-[50px] px-6 py-[10px] text-center font-red-hat text-white transition-colors"
                                        style={{
                                            letterSpacing: "0.02em",
                                            lineHeight: "160%",
                                        }}
                                        tabIndex={0}
                                        data-highlight="true"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <motion.div
                                className="absolute z-0 hidden origin-center rounded-[50px] xl:block"
                                animate={{
                                    width: navItems.find((item) => item.name === activeTab)
                                        ?.width,
                                    left: navItems.find((item) => item.name === activeTab)
                                        ?.position,
                                }}
                                initial={{
                                    width: navItems[0].width,
                                    left: navItems[0].position,
                                }}
                                transition={{
                                    ease: [0.22, 1, 0.36, 1],
                                    duration: 0.6,
                                }}
                                style={{
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    height: "46px",
                                    top: "calc(50% - 46px / 2)",
                                }}
                            />
                        </div>
                    </nav> */}

                    {/* <div className="hidden justify-end xl:flex">
                        <button className="base-button">
                            Get Started
                        </button>
                    </div> */}

                    {/* <div className="flex justify-end xl:hidden">
                        <button
                            onClick={() => setToggleMenu((prev) => !prev)}
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-white/5 md:h-[52px] md:w-[52px]"
                        >
                            {toggleMenu ? (
                                <X className="h-5 w-5 md:h-8 md:w-8" />
                            ) : (
                                <Menu className="h-5 w-5 md:h-8 md:w-8" />
                            )}
                        </button>
                    </div> */}
                    {/* <div
                        className={`col-span-2 flex w-full origin-top flex-col flex-nowrap items-center gap-8 bg-background transition-all duration-500 xl:hidden ${toggleMenu ? "opacity-1 overflow-auto h-[340px] py-2" : "h-0 opacity-0"}`}
                    >
                        {navItems.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                onClick={() => setActiveTab(item.name)}
                                className="relative z-10 rounded-[50px] text-center font-red-hat text-gray_brand-1 transition-colors hover:text-white hover:underline"
                                style={{
                                    letterSpacing: "0.02em",
                                    lineHeight: "160%",
                                }}
                                tabIndex={0}
                                data-highlight="true"
                            >
                                {item.name}
                            </a>
                        ))}
                        <button className="base-button">
                            Get Started
                        </button>
                    </div> */}
                </div>
            </motion.div>
        </header>
    );
};

export default Header;
