import React from "react";
import Hero from "./Hero";
import Details from "./Details";
import Integration from "./Integration";
import WhatWeDo from "./WhatWeDo";


const Home: React.FC = () => {
    return (
        <div>
            <Hero />
            <WhatWeDo />
            <Details />
            <Integration />
        </div>
    );
};

export default Home;