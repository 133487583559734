import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

import Logo from "assets/images/lastbitligi-02.png";
import Telegram from "assets/svgs/telegram";
import Twitter from "assets/svgs/x";
import socials from "constant/social";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const isXl = useMediaQuery({ query: "(max-width: 1200px)" });

  return (
    <footer id="pricing" className="bg-body_background_second">
      <div className="border-b border-zinc-800">
        <div className="mx-auto max-w-container px-6 py-10 md:px-[52px] md:py-14 xl:px-[120px]">
          <div className="flex flex-col items-center gap-4 overflow-hidden xl:items-start">
            <motion.div
              initial={{
                opacity: 0,
                ...(isXl ? { y: -30 } : { x: -60 }),
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              viewport={{ once: true }}
              transition={{
                ease: [0.6, 1, 0.5, 1],
                opacity: { duration: 1.5, delay: 0.3 },
                duration: 1,
                delay: 0.2,
              }}
              className="w-fit"
            >
              <p
                className="text-xs font-black uppercase md:text-sm"
                style={{ letterSpacing: "0.2em" }}
              >
                Get a quote
              </p>
            </motion.div>
            <div className="flex w-full flex-col items-center justify-between gap-4 overflow-hidden md:gap-8 xl:flex-row">
              <motion.div
                initial={{
                  opacity: 0,
                  ...(isXl ? { y: -40 } : { x: -60 }),
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                viewport={{ once: true }}
                transition={{
                  ease: [0.6, 1, 0.5, 1],
                  opacity: { duration: 1.5, delay: 0.3 },
                  duration: 1,
                  delay: 0.2,
                }}
                className="flex shrink-0 flex-col justify-start"
              >
                <p
                  className="text-wrap break-words text-center text-2xl font-black capitalize leading-tight sm:text-[30px] md:text-4xl xl:text-start xl:text-4xl"
                  style={{ letterSpacing: "0.01em", lineHeight: "140%" }}
                >
                  <span className="text-white bg-clip-text text-transparent">
                    Ready to pull the trigger? <br />
                  </span>
                </p>
                <p className="max-w-lg text-lg">
                  Step into the world of LastBit and discover a survival experience like no 
                  other. Whether you’re here to explore the gameplay, learn about upcoming 
                  features, or partner with us, we’re ready to assist.
                </p>
              </motion.div>
              <Link to={socials.twitter} target="_blank" className="base-button">
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-container px-6 py-10 md:px-[52px] xl:px-[120px]">
        <div className="flex w-full flex-col items-center justify-between gap-16 overflow-hidden xl:flex-row xl:items-start xl:gap-0">
          <motion.div
            initial={{
              opacity: 0,
              ...(isXl ? { y: -40 } : { x: -60 }),
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="flex flex-col items-center gap-8 overflow-hidden xl:items-start"
          >
            <div className="flex items-center">
              <img
                src={Logo}
                alt="lastbit_logo"
                className="aspect-square size-20"
              />
            </div>
            <p className="text-center text-gray_brand-1 xl:w-96 xl:text-start">
              LastBit delivers an unparalleled survival strategy game experience, combining intense zombie
              battles, resource management, and tactical decision-making in a post-apocalyptic world. Fight
              to survive, build your legacy, and claim the ultimate reward of 1 Bitcoin.
            </p>
            {/* <div className="flex flex-nowrap items-center gap-6">
                <Link to={socials.twitter} target="_blank">
                  <Twitter className="cursor-pointer fill-white size-6 hover:fill-gray-400" />
                </Link>
                <Link to={socials.telegram} target="_blank">
                  <Telegram className="cursor-pointer fill-white size-6 hover:fill-gray-400" />
              </Link>
            </div> */}
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
              ...(isXl ? { y: -40 } : { x: 60 }),
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="grid w-full grid-cols-2 gap-8 md:grid-cols-4 lg:col-span-4 lg:col-start-3 lg:gap-16 xl:w-fit"
          >
            {/* Quick Link */}
            {/* <div className="flex flex-col gap-6">
              <h4 className="text-center text-sm font-bold md:text-xl xl:text-start">
                Quick Link
              </h4>
              <div className="flex flex-col gap-6 md:gap-[10px]">
                {["Home", "How to play", "Features", "About Us"].map((item) => (
                  <p
                    key={item}
                    className="w-full cursor-pointer text-center text-xs text-gray_brand-1 transition-all duration-300 ease-out hover:font-semibold hover:text-white md:text-base xl:w-fit xl:text-start"
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div> */}

            {/* Who We Are */}
            {/* <div className="flex flex-col gap-6">
              <h4 className="text-center text-sm font-bold md:text-xl xl:text-start">
                Who We Are
              </h4>
              <div className="flex flex-col gap-6 md:gap-[10px]">
                {[
                  "About Us",
                  "Contact Us",
                  "Careers",
                  "FAQ",
                  "Insight",
                  "Help",
                ].map((item) => (
                  <p
                    key={item}
                    className="w-full cursor-pointer text-center text-xs text-gray_brand-1 transition-all duration-300 ease-out hover:font-semibold hover:text-white md:text-base xl:w-fit xl:text-start"
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div> */}

            {/* Follow Us */}
            <div className="flex flex-col gap-6">
              <h4 className="text-center text-sm font-bold md:text-xl xl:text-start">
                Follow Us
              </h4>
              <div className="flex flex-col gap-6 md:gap-[10px]">
                {[
                  {name: "Telegram", link: socials.telegram},
                  {name: "Twitter", link: socials.twitter},
                ].map((item) => (
                  <Link to={item.link}
                    key={item.name}
                    className="w-full cursor-pointer text-center text-xs text-gray_brand-1 transition-all duration-300 ease-out hover:font-semibold hover:text-white md:text-base xl:w-fit xl:text-start"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Company */}
            {/* <div className="flex flex-col gap-6">
              <h4 className="text-center text-sm font-bold md:text-xl xl:text-start">
                Company
              </h4>
              <div className="flex flex-col gap-6 md:gap-[10px]">
                {["Whitepaper"].map((item) => (
                  <p
                    key={item}
                    className="w-full cursor-pointer text-center text-xs text-gray_brand-1 transition-all duration-300 ease-out hover:font-semibold hover:text-white md:text-base xl:w-fit xl:text-start"
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div> */}
          </motion.div>
        </div>
      </div>
      <div className="mt-6 w-full overflow-hidden border-t border-zinc-800 md:mt-10">
        <div className="mx-auto flex max-w-container flex-col items-center justify-between px-6 py-5 md:flex-row md:px-[52px] md:py-10 xl:px-[120px]">
          <motion.p
            initial={{
              opacity: 0,
              y: 40,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="text-sm font-medium text-zinc-400 xl:text-base"
          >
            Copyright© {currentYear}. Lastbit. All Right Reserved.
          </motion.p>
          {/* <motion.div
            initial={{
              opacity: 0,
              y: 30,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="mt-4 flex gap-6 md:mt-0"
          >
            <button className="text-sm font-medium text-zinc-400 hover:text-zinc-300 lg:text-base">
              Terms of Service
            </button>
            <button className="text-sm font-medium text-zinc-400 hover:text-zinc-300 lg:text-base">
              Privacy Policy
            </button>
          </motion.div> */}
        </div>
      </div>
    </footer>
  );
}
