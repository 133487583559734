import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

import IntergrationImage from "assets/images/lastbit-22.jpg";
import { Link } from "react-router-dom";
import socials from "constant/social";

const Integration = () => {
  const isMd = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className="mx-auto max-w-container px-6 py-10 md:px-[52px] md:py-[80px] xl:px-[120px] xl:py-[50px]">
      <div className="grid grid-cols-1 items-center gap-10 overflow-hidden md:grid-cols-2 md:gap-4">
        <div className="order-2 flex w-full flex-col flex-nowrap items-center gap-4 md:order-1 md:items-start">
          <motion.div
            initial={{
              opacity: 0,
              ...(isMd ? { y: -30 } : { x: -80 }),
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="w-fit"
          >
            <p
              className="text-xs font-black uppercase lg:text-sm"
              style={{ letterSpacing: "0.2em" }}
            >
              Latest Release
            </p>
          </motion.div>

          <motion.div
            initial={{
              opacity: 0,
              ...(isMd ? { y: -40 } : { x: -80 }),
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="flex shrink-0 flex-col justify-start"
          >
            <p
              className="text-wrap break-words text-center text-2xl font-black capitalize md:text-start md:text-[32px] lg:text-[32px] xl:text-5xl"
              style={{ letterSpacing: "0.01em", lineHeight: "140%" }}
            >
              Survivor’s Update
            </p>
          </motion.div>

          <motion.div
            initial={{
              opacity: 0,
              ...(isMd ? { y: -30 } : { x: -80 }),
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="flex flex-col items-center gap-4 md:items-start md:gap-12"
          >
            <p
              className="text-center text-xs leading-relaxed text-gray_brand-1 md:text-start md:text-sm xl:text-base"
              style={{ lineHeight: "160%", letterSpacing: "0.02em" }}
            >
              Explore the latest features in LastBit. Strengthen your defenses, battle new zombie types with unique abilities, and adapt to an evolving resource economy. Every decision counts as you fight to survive 100 days and claim the ultimate prize: 1 Bitcoin.
            </p>
            <Link to={socials.game} target="_blank" className="base-button">
              Play Now
            </Link>
          </motion.div>
        </div>
        <div className="order-1 md:order-2">
          <motion.div
            initial={{
              opacity: 0,
              ...(isMd ? { y: -40 } : { x: 80 }),
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              x: 0,
            }}
            viewport={{ once: true }}
            transition={{
              ease: [0.6, 1, 0.5, 1],
              opacity: { duration: 1.5, delay: 0.3 },
              duration: 1,
              delay: 0.2,
            }}
            className="flex justify-center max-h-[340px] w-full overflow-hidden xl:max-h-none"
          >
            <img
              className="h-auto w-full max-w-md object-contain rounded-3xl"
              src={IntergrationImage}
              alt="IntergrationImage"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
