import React from 'react';
import BloodTexture from 'assets/images/bloodTexture.png';

const Details: React.FC = () => {
    return (
        <div
            className='w-full h-60 mb-40 my-12'
            style={{
                backgroundImage: `url(${BloodTexture})`,
            }}
        >
            <div className="mx-auto max-w-container flex flex-col items-center justify-center px-6 md:px-[52px] xl:px-[120px] h-full">
                <h6 className='text-center font-black text-3xl'>The Only Game Where Survival Meets Strategy</h6>
                <p className='md:max-w-xl mx-auto text-center mt-4 md:text-lg'>
                    Face relentless zombie hordes in LastBit, where every moment challenges your wit, courage,
                    and ability to thrive in a post-apocalyptic world. Outlast your enemies, build your defenses, and
                    claim the ultimate victory
                </p>
            </div>
        </div>
    );
};

export default Details;